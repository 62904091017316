.img-card {
    width: 50px;
}

.icon {
    padding-bottom: 20px;
}

.card-header  {
    background: #f7f7f7 !important;
    font-weight: bold;
    text-align: center;
    color: black !important;
}

.tb-barrel-tap {
    width: 100% !important;
}

.monitor-header {
    background-color: #485563 !important;
    color: #fff !important;
}

.bt-actions-trash {
    margin-bottom: 10px;
}