.float{
	position:fixed;
	width:60px;
	height:60px;
	bottom:40px;
	right:40px;
    background: linear-gradient(60deg, #29323c 0%, #485563 100%);
	color:#FFF;
	border-radius:50px;
	text-align:center;
	box-shadow: 2px 2px 3px #999;
}

.my-float{
	margin-top:22px;
}