.content {
    background-color: #f4f6f9 !important;
}

.sidebar {
    height: 100vh !important;
    background: linear-gradient(60deg, #29323c 0%, #485563 100%);
    padding: 0;

}

.txt-beer {
    color: rgb(204, 193, 46);
}

.app-header {
    background-color: #45505e;
    margin: 0;
    width: 100% !important;
}

.text-center {
    text-align: center;
}

.icon-user {
    font-size: 50px;
    padding: 10px;
}

.app-user-info {
    color: #fff;
    background-color: #394450;
    padding: 10px;
    text-align: center !important;
    justify-content: center !important;
}

.version {
    font-size: 15px;
    color: #acacac;
}

.user-info {
    width: 100vh;
    margin-left: 20px;
}

.content {
    padding: 30px;
}

.btn-space {
    margin-left: 15px;
}

.field-required {
    color: red;
    font-weight: bold;
}

.spinner-center {
    justify-content: center;
}

.sidebar-dark-primary .nav-sidebar>.nav-item>.nav-link.active, .sidebar-light-primary .nav-sidebar>.nav-item>.nav-link.active {
    background-color: #6e757c;
    color: #fff;
}

