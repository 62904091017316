
  .empty-state {
    text-align: center;
    padding: 30px;
    border-radius: 10px;
  }
  
  .empty-state img {
    max-width: 100%;
    height: auto;
  }
  
  .empty-state h2 {
    font-size: 24px;
    margin-top: 20px;
    color: #333333;
  }
  
  .empty-state p {
    font-size: 16px;
    margin-top: 10px;
    color: #666666;
  }
  

  .folder-empty {
    font-size: 160px;
    color: #666666;
  }