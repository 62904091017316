.md-header {
    font-weight: bold;
    width: 100%;
    text-align: center;
    padding: 10px;
    background-color: #ebebeb;
    font-weight: bold;
}


.Modal {
    position: absolute;
    background-color: #fff;
    min-width: 500px;
}

.Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgb(143, 143, 143);
}

.md-content {
    padding: 30px;
    text-align: center;
    font-size: 20px;
}

.md-footer {
    padding-bottom: 20px !important;
    padding-top: 20px;
    text-align: end;
    padding-right: 10px;
    background-color: #ebebeb;
}
