.buttons-action {
    margin-right: 8px;
}

.new-company {
    justify-content: space-between
}

.btn-new-company {
   margin-right: 10px;
}

.box-footer {
    float: right;
}