.label-add-card {
    margin-left: 10px;
}

.alert-balance {
    min-height: 250px;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.txt-balance {
    padding-top: 30px;
}

.alert-balance-info {
    min-height: 40px;
}

.alert-balance-value-info {
    min-height: 90px;
}

.balance-value {
    padding-top: 23px;
}

.balance-info {
    padding-top: 8px;
    padding-bottom: 8px;
}

.bg-type-balance {
    min-width: 100px;
}

.center-text-info {
    text-align: center;
    color: rgb(112, 111, 111);
    padding-bottom: 50px;
    padding-top: 100px;

}

.center-pulse {
    justify-content: center;
}

.pulse {
    width: 100px;
    height: 100px;
    background: #FF6D4A;
    border-radius: 50%;
    color: #FFF;
    font-size: 20px;
    text-align: center;
    line-height: 100px;
    font-family: sans-serif;
    text-transform: uppercase;
    animation: animate-pulse 3s linear infinite;
    cursor: pointer
}

@keyframes animate-pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(255, 109, 74, 0.7), 0 0 0 0 rgba(255, 109, 74, 0.7);
    }

    40% {
        box-shadow: 0 0 0 50px rgba(255, 109, 74, 0.0), 0 0 0 0 rgba(255, 109, 74, 0.7);
    }

    80% {
        box-shadow: 0 0 0 50px rgba(255, 109, 74, 0.0), 0 0 0 30px rgba(255, 109, 74, 0);
    }

    100% {
        box-shadow: 0 0 0 0 rgba(255, 109, 74, 0.0), 0 0 0 30px rgba(255, 109, 74, 0);
    }

}

.btns-actions-register {
    margin-bottom: 15px;
}

.input-document {
    justify-content: center;
}

.img-logo-card {
    width: 90px;
}

.notfound-card {
    width: 100%;
    justify-content: center;
}

.bt-actions-trash {
    margin-top: 10px;
    margin-left: 10px;
}