.progress-lte {
    width: 70% !important;
}

.info-subtitle {
    font-size: 14px;
}

.small-box:hover {
    background-color: rgb(58, 58, 58) !important;
    cursor: grab;
}

.img-barrel {
    width: 50px;
}

.progress-success {
    background-color: #1b5029 !important;
}

.progress-danger {
    background-color: #f77c7c !important;
}

.progress-warning {
    background-color: #c9be28 !important;
}

.spinner-barrel {
    justify-content: center;
}

.bt-actions {
    background-color: rgb(58, 58, 58) !important;
    padding: 10px;
    text-align: center;
}

.bt-actions-trash {
    margin-bottom: 10px;
}

.inner {
    margin-left: 10px;
    padding-top: 10px;
}

.gain-negative {
    color: rgb(238, 23, 23);
}